import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,7];

export const dictionary = {
		"/(app)": [~20,[3],[4]],
		"/(app)/buy-again": [22,[3],[4]],
		"/(admin)/cms": [9,[2]],
		"/(admin)/cms/areas": [10,[2]],
		"/(admin)/cms/brands": [11,[2]],
		"/(admin)/cms/editable/home": [12,[2]],
		"/(admin)/cms/images": [13,[2]],
		"/(admin)/cms/inserts": [14,[2]],
		"/(admin)/cms/map": [~15,[2]],
		"/(admin)/cms/order/[id]": [~16,[2]],
		"/(admin)/cms/restaurants": [17,[2]],
		"/(admin)/cms/settings": [18,[2]],
		"/(admin)/cms/tags": [19,[2]],
		"/(app)/delivery/best-restaurants": [~25,[3],[4,6]],
		"/(app)/delivery/in/[...area]": [~26,[3],[4,6]],
		"/(app)/delivery/of/[tag]": [~27,[3],[4,6]],
		"/(app)/delivery/restaurants-near-me/[[page]]": [~28,[3],[4,6]],
		"/(app)/delivery/[vertical=seoVertical]/[slug]-near-me": [~23,[3],[4,6]],
		"/(app)/delivery/[vertical=vertical]/[name]/[id]": [~24,[3],[4,6]],
		"/env": [48],
		"/env/platform": [49],
		"/env/push": [50],
		"/env/releasenotes": [51],
		"/(app)/faq": [29,[3,7],[4]],
		"/(app)/faq/articles/[id]": [30,[3,7],[4]],
		"/(app)/faq/sections/[id]": [31,[3,7],[4]],
		"/(app)/gifting": [32,[3],[4]],
		"/(app)/gifting/gifts": [33,[3],[4]],
		"/(app)/id": [34,[3],[4]],
		"/(app)/profile": [35,[3,8],[4]],
		"/(app)/profile/cards": [36,[3,8],[4]],
		"/(app)/profile/me": [37,[3,8],[4]],
		"/(app)/profile/orders": [38,[3,8],[4]],
		"/(app)/profile/smartshopper": [39,[3,8],[4]],
		"/(app)/profile/wallet": [40,[3,8],[4]],
		"/(app)/reset-password/[token]": [41,[3],[4]],
		"/(app)/signin": [42,[3],[4]],
		"/(app)/simulate/[vertical=vertical]/order/details": [43,[3],[4]],
		"/(app)/styleguide": [44,[3],[4]],
		"/(order)/[vertical=vertical]/order/[id]/checkout": [45],
		"/(order)/[vertical=vertical]/order/[id]/checkout/[outcome]": [46],
		"/(order)/[vertical=vertical]/order/[id]/driver": [47],
		"/(app)/[vertical]/order/[id]/details": [21,[3,5],[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';