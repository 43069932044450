import { Subject, map, mergeScan, of, withLatestFrom } from "rxjs";

let i = 0;

export type Er = {
	id: string;
	message: string;
	er: object;
};

const subjOnError = new Subject<Er>();
const subjOnErrorUpdate = new Subject<Omit<Er, 'message'>>();

/**
 * Like a list of messages that users need to be aware of (toasts), but for errors.
 * @param message A user friendly message.
 * @param error The error and everything we know about it. May be a string, an object, or an Error.
 * @param id Optional unique identifier for the error. If not provided, it will be an incrementing number.
 * @returns The id of the error. Use this to update the error later.
 */
export function registerError<T extends object>(message:string, error?:T, id?:number|string) {
	if (typeof id === 'undefined') id = i++;
	if (typeof id === 'number') id = id.toString(32);
	const er = error || { message };
	subjOnError.next({ id, message, er });
	return id;
}
/**
 * Perform an update on an error. This is useful for updating the error object with more information or changing states.
 * @param id The id of the error to update.
 * @param er Partial error object to update. If the error object has a proprty that already exists, it will be overwritten.
 */
export function updateError(id:string, er:object) {
	subjOnErrorUpdate.next({ id, er });
}

const navErrorsUpdate$ = subjOnErrorUpdate.pipe(
	mergeScan((acc, { id, er }) => {
		const i = acc.findIndex(e => e.id === id);
		if (~i) acc[i].er = { ...er };
		return of(acc);
	}, [] as Er[]),
);

export const navErrors$ = subjOnError.pipe(
	mergeScan((acc, er) => {
		acc.push(er);
		return of(acc);
	}, [] as Er[]),
	withLatestFrom(navErrorsUpdate$),
	// apply updates
	map(([errors, updates]) => errors.map(e => {
		const ups = updates.filter(u => u.id === e.id).map(u => u.er);
		const latest = { ...e };
		ups.forEach(update => latest.er = { ...e.er, ...update });
		return latest;
	})),
);

